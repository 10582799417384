<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal-wallid"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header">
            {{ $t('createCertModal.title') }}
            <v-spacer />
            <button
              v-if="isMaster"
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
              <IconClose />
            </button>
          </slot>
        </header>

        <div class="modal-body">
          <slot name="body">
            <v-stepper class="stepper-signup" v-model="step">
              <v-stepper-items>
                <v-stepper-content step="0" class="pa-0">
                  <v-container id="scroll" class="pb-16 scroll">
                    <v-row>
                      <v-col
                        id="photo-container"
                        style="justify-content: center"
                        cols="4"
                        md="3"
                        class="pb-2"
                      >
                        <div class="wrapper">
                          <div
                            v-if="photoFromFile || photoFromDB"
                            class="company-header-avatar"
                            :style="
                              'background-image: url(' +
                              ' \' ' +
                              (photoFromFile || photoFromDB) +
                              '\'' +
                              ')'
                            "
                          ></div>
                          <!-- <v-img
                            v-if="!photoURL"
                            class="mx-auto"
                            max-height="145"
                            max-width="145"
                            :src="photoURL"
                          /> -->
                          <ImageProfile
                            v-if="!(photoFromDB || photoFromFile)"
                          />
                        </div>
                      </v-col>
                      <v-col
                        v-if="photoFromDB == DEFAULT_PHOTO || !photoFromDB"
                        id="errorUrlPhoto"
                        cols="8"
                        md="8"
                        class="input-field pb-2"
                      >
                        <p class="small_text">
                          {{ $t('createCertModal.upload[0]') }}
                        </p>
                        <p class="mb-0 small_text">
                          {{ $t('createCertModal.upload[1]') }}
                        </p>
                        <p class="small_text">
                          {{ $t('createCertModal.upload[2]') }}
                        </p>
                        <input
                          id="fileUpload"
                          type="file"
                          hidden
                          @input="clearError('errorUrlPhoto')"
                          accept="image/x-png,image/gif,image/jpeg"
                        />
                        <v-btn class="back" @click="chooseFiles()">
                          {{ $t('createCertModal.uploadButton') }}
                        </v-btn>
                        <p
                          class="error--text mt-2"
                          v-if="errorsMsg['errorUrlPhoto']"
                        >
                          {{ errorsMsg['errorUrlPhoto'] }}
                        </p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        id="errorCertName"
                        class="input-field pb-0"
                      >
                        <label class="">{{ $t('createCertModal.name') }}</label>
                        <v-text-field
                          class="mt-1"
                          v-model="certName"
                          flat
                          solo
                          @input="clearError('errorCertName')"
                          :error-messages="errorsMsg['errorCertName']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" class="input-field pb-0">
                        <label class="">{{
                          $t('createCertModal.radio[0]')
                        }}</label>
                        <v-radio-group v-model="side" row>
                          <v-radio
                            color="#009fb1"
                            :label="$t('createCertModal.radio[1]')"
                            :value="0"
                          ></v-radio>
                          <v-radio
                            color="#009fb1"
                            :label="$t('createCertModal.radio[2]')"
                            :value="1"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>

                      <v-col cols="12" id="front" class="pb-2 pt-2">
                        <FrontSideTemplate
                          v-if="side >= 0"
                          @update="(e) => (frontTemplate = e)"
                          :errorsMsg="errorsMsg"
                          @clearError="clearError"
                        />
                      </v-col>
                      <v-col cols="12" id="back" class="pb-2 pt-2">
                        <BackSideTemplate
                          v-if="side == 1"
                          @update="(e) => (backTemplate = e)"
                          :errorsMsg="errorsMsg"
                          @clearError="clearError"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-stepper-content>

                <v-stepper-content step="1" class="pa-0">
                  <v-container class="">
                    <v-row>
                      <v-col cols="12" class="pt-1">
                        <p class="subtitle_text mb-1">
                          {{ $t('createCertModal.card') }}
                        </p>
                      </v-col>
                      <v-col cols="12" md="12" class="pb-1">
                        <CustomCard
                          v-if="step == 1"
                          :frontTemplate="frontTemplate"
                          :backTemplate="backTemplateHeaders"
                          :frontend_props="frontend_props"
                          :caName="caName"
                          :credentialName="certName"
                          :urlPhoto="photoFromFile || photoFromDB"
                        />
                      </v-col>
                      <v-col cols="12" md="12" class="pb-2 pt-1">
                        <p class="light_text">
                          {{ $t('createCertModal.hintCard') }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <v-row class="justify-end" no-gutters>
              <v-btn class="back mr-4" v-if="step == 1" @click="back()">{{
                $t('button.back')
              }}</v-btn>
              <v-btn
                v-show="step == 0"
                class="next"
                :loading="isDisabled"
                :disabled="isDisabled"
                @click="next()"
                >{{ advanceButtonText }}</v-btn
              >
              <v-btn
                v-show="step == 1"
                class="next"
                :loading="isDisabled"
                :class="confirmed ? 'confirm' : ''"
                :disabled="isDisabled"
                @click="createTemplateButton()"
                >{{ advanceButtonText }}</v-btn
              >
            </v-row>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconClose from '../assets/icon-close.vue';
import ImageProfile from '../assets/image-profile.vue';

import FrontSideTemplate from '../components/FrontSideTemplate';
import BackSideTemplate from '../components/BackSideTemplate';

import CustomCard from '../components/CustomCard';

const API_URL_IMAGES = process.env.VUE_APP_API_URL_IMAGES;

const DEFAULT_PHOTO = `${API_URL_IMAGES}/logos/default-image-profile.png?Transparency=true`;

import {
  CREATE_TEMPLATE,
  DASHBOARD,
  TEMPLATE_ITEM,
  TEMPLATE_SYNC,
  UPDATE_CA,
  UPLOAD_FILE,
} from '../store/actions';

import { mapGetters } from 'vuex';

export default {
  name: 'NewTemplate',
  components: {
    IconClose,
    FrontSideTemplate,
    BackSideTemplate,
    CustomCard,
    ImageProfile,
  },
  data() {
    return {
      step: 0,
      side: -1,
      hasBack: false,
      confirm: false,
      sending: false,
      confirmed: false,
      certName: null,
      photoFile: null,
      photoFromFile: null,
      frontend_props: {},
      publicAttribute: '',
      advanceButtonText: this.$t('button.next'),
      hasErrors: false,
      errorsMsg: {},
      frontTemplate: [],
      backTemplate: [],
      backTemplateHeaders: {},
      currentColor: '#eeeeee',
      customColor: { 'eneacoaching.com': '#eac381', 'wallid.io': '#eac381' },
      DEFAULT_PHOTO: DEFAULT_PHOTO,
    };
  },
  created: function () {
    this.debug('photoFromDB', this.photoFromDB);
  },
  mounted() {
    let el = document.getElementById('fileUpload');
    if (el) {
      el.addEventListener('change', (event) => {
        this.photoFile = event.target.files[0];
        this.photoFromFile = URL.createObjectURL(this.photoFile);
      });
    }
  },
  computed: {
    ...mapGetters(['isMaster', 'caName', 'photoFromDB', 'cid', 'adminEmail']),

    isDisabled() {
      return this.hasErrors || this.sending;
      // return false;
    },
  },
  watch: {
    side(id) {
      let sides = ['front', 'back'];

      //Scroll to @sides on change

      //Scroll to @sides on change
      let side = sides[id];
      if (side) this.scrollIntoID(side, 200);

      //Clear all error on template side change
      this.backTemplate = [];
      this.backTemplateHeaders = {};
      Object.keys(this.errorsMsg).forEach((error) => this.clearError(error));
    },
  },
  methods: {
    /**
     *
     * Delete @error from errors list
     */
    clearError(error) {
      this.$delete(this.errorsMsg, error);
      this.hasErrors = Object.keys(this.errorsMsg).length != 0;
    },

    checkForm() {
      this.errorsMsg = {};
      this.hasErrors = false;

      if (!(this.photoFromFile || this.photoFromDB)) {
        this.errorsMsg['errorUrlPhoto'] = this.$t(
          'createCertModal.errorUrlPhoto'
        );
      }

      if (!this.certName) {
        this.errorsMsg['errorCertName'] = this.$t(
          'createCertModal.errorCertName'
        );
      }
      if (this.frontTemplate.length < 1) {
        this.errorsMsg['errorAttribute'] = this.$t(
          'createCertModal.errorAttribute'
        );
        this.errorsMsg['errorInputFront'] = this.$t(
          'createCertModal.errorInput'
        );
      }

      if (this.side == 1 && this.backTemplate.length < 1) {
        this.errorsMsg['errorModule'] = this.$t('createCertModal.errorModule');
        this.errorsMsg['errorType'] = this.$t('createCertModal.errorType');
        this.errorsMsg['errorInputBack'] = this.$t(
          'createCertModal.errorInput'
        );
      }

      // if (!this.publicAttribute) {
      //   this.errorsMsg["errorPublicField"] = this.$t(
      //     "createCertModal.errorPublicField"
      //   );
      // }

      this.hasErrors =
        Object.keys(this.errorsMsg).length > 0 &&
        this.errorsMsg.constructor === Object;

      if (this.hasErrors) {
        this.scrollIntoError(Object.entries(this.errorsMsg)[0][0], -200);
      }

      this.$log.debug('hasErrors ', this.hasErrors);
      this.$log.debug('hasErrors ', this.errorsMsg);

      return this.hasErrors;
    },

    /**
     * On close reloads dashboard before closing this modal
     */
    close(e) {
      this.$store.dispatch(DASHBOARD).then(() => {
        this.$emit('close', e);
      });
    },
    back() {
      this.confirmed = false;
      this.sending = false;
      switch (this.step) {
        case 1:
          this.step -= 1;
          this.advanceButtonText = this.$t('button.next');

          break;

        default:
          this.$emit('back');

          break;
      }
    },

    async createTemplateButton() {
      this.sending = true;
      // this.advanceButtonText = this.$t("button.confirm");

      this.frontend_props.background_url =
        (await this.generatePNGfromSVG()) ||
        'https://wallid-backend.s3-eu-west-1.amazonaws.com/frontend/background_silver.png';
      console.log('Call createTemplate ', this.frontend_props);
      if (this.photoFile) {
        this.uploadPhoto(this.photoFile).then((photoURL) => {
          this.updateCA(photoURL);
        });
      } else {
        this.updateCA(this.photoFromDB);
      }
    },

    uploadPhoto(file, folder) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(UPLOAD_FILE, { file, folder })
          .then((url) => {
            resolve(url);
          })
          .catch((err) => {
            this.logError('uploadPhotos', err);
            reject(err);
          });
      });
    },

    isCustomColor() {
      return (
        this.adminEmail &&
        this.customColor[this.getEmail(this.adminEmail).domain]
      );
    },
    next() {
      if (!this.checkForm()) {
        console.log('Advance step ');
        this.step += 1;
        this.advanceButtonText = this.$t('button.create');

        this.frontend_props.color = this.isCustomColor() || this.currentColor;

        console.log(this.frontend_props);
        this.createTableHeaders();
      }
    },

    chooseFiles() {
      document.getElementById('fileUpload').click();
    },

    createTableHeaders() {
      if (this.backTemplate && this.backTemplate.length > 0) {
        this.backTemplateHeaders = this.createTable(this.backTemplate);
      }
    },
    createTable(template) {
      console.log(template);
      let attrs = [];

      let headersTable = template.map((e) => {
        console.log(e);
        attrs.push({ attr: e.text.toLowerCase(), type: e.input });

        return {
          text: e.text,
          align: 'start',
          value: e.text.toLowerCase(),
          type: e.input,
        };
      });

      // attrs.unshift({ attr: template[0].module, type: "text" });

      // headersTable.unshift({
      //   text: template[0].module,
      //   align: "start",
      //   value: template[0].module.toLowerCase(),
      //   type: "text",
      // });
      console.log(headersTable);

      return { headers: headersTable, attrs: attrs };
    },
    addTemplateAttr(data) {
      let attrs = this.frontTemplate.map((item, index) => {
        console.log(item);
        var newItem = {
          attr: item.attr,
          type: item.input,
          // value: item.value,
          isPublic:
            item.attr.toLowerCase() == 'nome' ||
            item.attr.toLowerCase() == 'name' ||
            item.attr.toLowerCase() == 'nombre', //this.publicAttribute,
          isMandatory: item.isMandatory, // adicionar var para marcar se é obrigatorio
          order: index,
        };
        return newItem;
      });
      let tables = [];
      //TO DO: possibilidade de adicionar mais tabelas
      console.log('backTemplate', this.backTemplate);

      if (this.backTemplate && this.backTemplate.length > 0) {
        tables.push(this.createTable(this.backTemplate));
      }

      console.log('tables', tables);

      console.log('data', data);
      console.log('TEMPLATE_ITEM', TEMPLATE_ITEM);
      console.log('TEMPLATE_SYNC', TEMPLATE_SYNC);
      console.log('attrs', attrs);

      this.$store
        .dispatch(TEMPLATE_ITEM, {
          tid: data.tid,
          attrs,
          tables,
        })
        .then((res) => {
          console.log(res);
          let tid = res.data.data.template.tid;
          this.$store
            .dispatch(TEMPLATE_SYNC, res.data.data)
            .then(() => {
              this.close({ tid, name: '' });
            })
            .catch((err) => {
              console.error(err);
              this.$store.commit('errorMsg', err.message || err);
              this.sending = false;
            });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('errorMsg', err.message || err);
        });
    },
    updateCA(photoURL) {
      this.$store
        .dispatch(UPDATE_CA, { name: this.caName, img_url: photoURL })
        .then((cid) => {
          if (cid) {
            this.createTemplate();
          }
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('errorMsg', err.message || err);
          this.sending = false;
        });
    },
    createTemplate() {
      this.$store
        .dispatch(CREATE_TEMPLATE, {
          name: this.certName,
          frontend_props: this.frontend_props,
        })
        .then((data) => {
          console.log(data);
          this.addTemplateAttr(data);
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('errorMsg', err.message || err);
          this.sending = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.modal-body {
  padding: 0;
  padding-left: 16px;
}
#photo-container {
  position: relative;
  div.wrapper {
    max-width: 150px;
    height: 150px;
    border: solid 1px #eeeeee;
    border-radius: 50%;
    padding: 1px;
  }
}
.company-header-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  float: left;
  background-size: contain;
  background-position: center center;
}
</style>

<style lang="scss">
.modal-wallid {
  width: 830px;
  .confirm.v-btn {
    background-color: #00808e !important ;
  }
  .container.scroll {
    max-height: 420px;
    overflow: auto;
    padding-right: 18px;
  }
  // .static_image {
  //   .v-image {
  //     position: absolute;
  //   }
  // }
  .v-btn.add_more {
    border-radius: 4px;
    border: solid 1px var(--teal-blue);
    min-width: unset;
    padding: 0 16px;
    height: 44px;
    color: var(--teal-blue);
    max-width: 102px;
    .v-btn__content {
      text-transform: none;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--teal-blue);
      svg {
        margin-right: 8px;
      }
    }
    &.v-btn--disabled {
      color: #d8d8d8 !important;
      border-color: #d8d8d8 !important;
      .v-btn__content {
        color: #e5e5e5;

        svg g {
          fill: #e5e5e5;
        }
      }
    }
  }
}
</style>
