<template>
  <div class="v-grid" :style="style">
    <draggable v-model="list" tag="tbody" @end="onDragEnd">
      <transition-group tag="div" class="grid" name="grid">
        <GridItem
          v-for="v in list"
          :key="v.index"
          :index="v.index"
          :sort="v.sort"
          :item="v.item"
          :remove="
            () => {
              removeItem(v);
            }
          "
          :hasMandatory="hasMandatory"
          :draggable="draggable"
          :drag-delay="dragDelay"
          :cell-width="cellWidth"
          :cell-height="cellHeight"
          :window-width="windowWidth"
          @click="click"
          @updateMandatory="updateMandatory"
        >
          <slot
            name="cell"
            :item="v.item"
            :index="v.index"
            :sort="v.sort"
            :remove="
              () => {
                removeItem(v);
              }
            "
          >
          </slot> </GridItem
      ></transition-group>
    </draggable>
  </div>
</template>
<script>
import draggable from 'vuedraggable';

import util from './util.js';
import GridItem from './GridItem.vue';
export default {
  name: 'Grid',
  mixins: [util],
  components: {
    GridItem,
    draggable,
  },
  props: {
    hasMandatory: {
      type: Boolean,
    },
    items: {
      type: Array,
      default: () => [],
    },
    gridWidth: {
      type: Number,
      default: -1,
    },
    cellWidth: {
      type: Number,
      default: 250,
    },
    cellHeight: {
      type: Number,
      default: 64,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    dragDelay: {
      type: Number,
      default: 0,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  watch: {
    items: {
      handler: function (nextItems = []) {
        this.list = nextItems.map((item, index) => {
          return {
            item,
            index: index,
            sort: index,
          };
        });
        // this.$emit("drag", this.wrapEvent());
      },
      immediate: true,
    },
  },
  computed: {
    height() {
      return (
        Math.ceil(this.list.length / this.rowCount) * (this.cellHeight + 20)
      );
    },
    gridResponsiveWidth() {
      if (this.gridWidth < 0) {
        return this.windowWidth;
      } else {
        return Math.min(this.windowWidth, this.gridWidth);
      }
    },
    rowCount() {
      return Math.floor(this.gridResponsiveWidth / this.cellWidth);
    },
    style() {
      return {
        height: this.height + 'px',
      };
    },
  },
  methods: {
    updateMandatory(e) {
      console.log(e);
      this.$emit('updateMandatory', this.wrapEvent(e));
    },
    /* Returns merged event object */
    wrapEvent(other = {}) {
      return {
        datetime: Date.now(),
        items: this.getListClone(),
        ...other,
      };
    },
    /* Returns sorted clone of "list" array */
    getListClone() {
      return this.list;
      // .slice(0).sort((a, b) => {
      //   return a.sort - b.sort;
      // });
      //  .map(v => {
      //    return { ...v.item }
      //  })
    },
    removeItem({ index }) {
      console.log(index);

      this.$emit('remove', this.wrapEvent({ index }));
    },
    onDragStart(event) {
      this.$emit('dragstart', this.wrapEvent(event));
    },
    onDragEnd(event) {
      //   this.futureItem = this.list[this.futureIndex];
      //   this.movingItem = this.list[this.movingIndex];
      //   const _items = Object.assign([], this.list);
      //   _items[this.futureIndex] = this.movingItem;
      //   _items[this.movingIndex] = this.futureItem;

      //   this.items = _items;
      console.log(event);
      this.$emit('dragend', this.wrapEvent(event));
    },
    handleMove(e) {
      // const { index, futureIndex } = e.draggedContext;
      // this.movingIndex = index;
      // this.futureIndex = futureIndex;
      // return false; // disable sort
      this.onDrag(e);
    },
    click(event) {
      this.$emit('click', this.wrapEvent(event));
    },
    onDrag(event) {
      if (this.sortable) {
        this.sortList(event.index, event.gridPosition);
      }
      this.$emit('drag', this.wrapEvent({ event }));
    },
  },
};
</script>
<style lang="scss">
.v-grid {
  display: block;
  position: relative;
  width: 100%;
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 250px);
    grid-template-rows: repeat(3, 70px);
    grid-gap: 1em;
  }
}
</style>
