<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols="12">
        <p class="mb-0 strong_text">
          {{ $t('createCertModal.back') }}
        </p>
      </v-col>
      <v-col cols="12">
        <p class="mb-0 small_text">
          {{ $t('createCertModal.backSide.add[0]') }}
        </p>
      </v-col>
    </v-row>
    <v-row v-if="type.value == 'table'">
      <v-col cols="12" class="pr-2 py-2 input-field">
        <label class="">{{ $t('createCertModal.backSide.dropdown[0]') }}</label>
        <v-select
          class="field_changer"
          v-model="type"
          :items="types[$i18n.locale]"
          item-text="text"
          item-value="value"
          single-line
          attach
          return-object
          :ripple="false"
          :menu-props="{ contentClass: 'field_changer v-select select ' }"
        >
          <template v-slot:append> <IconDropDown /> </template>
        </v-select>
      </v-col>
      <v-col id="errorModule" class="pr-2 py-2 input-field">
        <label class="">{{ $t('createCertModal.backSide.dropdown[0]') }}</label>

        <v-combobox
          class="field_changer"
          disable-lookup
          v-model="columnName"
          :items="modulesList[$i18n.locale]"
          :placeholder="attributePlaceholder"
          single-line
          attach
          :ripple="false"
          :error-messages="errorsMsg['errorModule']"
          :menu-props="{ contentClass: 'field_changer select' }"
          @input="addToList"
        >
          <template v-slot:append> <IconDropDown /> </template>
          <template v-slot:item="{ item }">
            <IconAdd class="mr-1 mt-0" v-if="item.type === 'custom'" />
            <p
              class="v-list-item__title py-2 mb-0"
              :style="item.type === 'custom' ? 'color: var(--teal-blue);' : ''"
              hide-details
              solo
            >
              {{ item.text }}
            </p>
          </template>
        </v-combobox>
      </v-col>

      <v-col class="pr-2 py-2 input-field">
        <label class="">{{ $t('createCertModal.backSide.dropdown[2]') }}</label>
        <v-combobox
          class="field_changer"
          v-model="input"
          :items="inputList[$i18n.locale]"
          :placeholder="$t('createCertModal.backSide.placeholder[1]')"
          single-line
          attach
          :ripple="false"
          :error-messages="errorsMsg['errorInputBack']"
          :menu-props="{ contentClass: 'field_changer select' }"
        >
          <template v-slot:append> <IconDropDown /> </template>
        </v-combobox>
      </v-col>
      <v-col class="pt-11 pb-2 px-1" style="max-width: 104px">
        <v-btn class="add_more" :disabled="isAddDisabled" text @click="add()">
          <!-- <IconAdd /> -->
          {{ $t('createCertModal.backSide.add[1]') }}</v-btn
        >
      </v-col>
      <v-col cols="12" md="12" class="pt-0">
        <p class="error--text my-0 mt-n6" v-if="showMaxColsError">
          {{ $t('createCertModal.backSide.maxColumns') }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="py-0">
        <grid
          :draggable="true"
          :sortable="true"
          :items="techList"
          :height="100"
          :gridWidth="750"
          :cellHeight="53"
          @dragend="updateInitialFields"
          @remove="removeField"
        >
        </grid>
      </v-col> </v-row
  ></v-container>
</template>

<script>
import IconDropDown from '../../assets/icon-dropdown.vue';
import IconAdd from '../../assets/icon-add.vue';
import Grid from '../Grid';

export default {
  name: 'FrontSideTemplate',
  components: {
    IconAdd,
    IconDropDown,
    Grid,
  },
  props: {
    errorsMsg: {},
  },
  watch: {
    techList(value) {
      if (value.length > 0) {
        this.$emit('clearError', 'errorModule');
        this.$emit('clearError', 'errorType');
        this.$emit('clearError', 'errorInputBack');
      }
      if (value.length < 3) {
        this.showMaxColsError = false;
      }
    },
  },
  mounted() {
    this.moduleValue = this.modulesList[this.$i18n.locale][0];
    console.log(this.moduleValue);
  },
  computed: {
    isAddDisabled() {
      return !this.columnName || !this.input;
    },
  },
  data() {
    return {
      techList: [],
      showMaxColsError: false,
      attributePlaceholder: this.$t('createCertModal.frontSide.placeholder[0]'),
      type: { text: 'Tabela', value: 'table' },
      types: {
        pt: [{ text: 'Tabela', value: 'table' }],
        en: [{ text: 'Table', value: 'table' }],
        es: [{ text: 'Tabela', value: 'table' }],
        // [
        //   { text: "text", value: "text" },
        //   { text: "dd--mm-yyyy", value: "date" },
        //   { text: "number", value: "number" },
        // ],
      },
      input: null,
      inputList: {
        pt: [
          { text: 'texto', value: 'text' },
          { text: 'número', value: 'number' },
        ],
        en: [
          { text: 'text', value: 'text' },
          { text: 'number', value: 'number' },
        ],
        es: [
          { text: 'texto', value: 'text' },
          { text: 'numero', value: 'number' },
        ],
        // [
        //   { text: "text", value: "text" },
        //   { text: "dd--mm-yyyy", value: "date" },
        //   { text: "number", value: "number" },
        // ],
      },
      columnName: null,
      modulesList: {
        pt: [
          { text: 'Tecnologia', value: 'tecnologia', type: 'text' },
          { text: 'Nota', value: 'grade', type: 'text' },
          { text: 'Duração', value: 'text', type: 'number' },
          { text: 'Adicionar Outro', type: 'custom' },
        ],
        en: [
          { text: 'Technology', value: 'tecnologia', type: 'text' },
          { text: 'Grade', value: 'grade', type: 'text' },
          { text: 'Duration', value: 'duration', type: 'number' },
          { text: 'Add another', type: 'custom' },
        ],
        es: [
          { text: 'Tecnología', value: 'tecnologia', type: 'text' },
          { text: 'Notas', value: 'grade', type: 'text' },
          { text: 'Horas', value: 'text', type: 'number' },
          { text: 'Adicionar Outro', type: 'custom' },
        ],
      },
    };
  },
  methods: {
    add() {
      console.log('input', this.input);
      console.log('moduleValue', this.moduleValue);

      if (this.techList.length < 3) {
        this.techList.push({
          attr: this.columnName.text || this.columnName,
          module: this.columnName.text || this.columnName,
          text: this.columnName.text || this.columnName,
          value: this.columnName.text || this.columnName,
          align: 'start',
          type: this.input.text || this.input,
          input: this.input.value,
        });
      } else {
        this.showMaxColsError = true;
      }
      this.columnName = null;
      this.input = null;
      this.$emit('update', this.techList);
      console.log('techList', this.techList);
    },
    addToList(v) {
      console.log('Attribute', v);

      //   var newValue = { text: "", value: this.attributeList.length };
      if (v.type && v.type == 'custom') {
        // newValue.text = v.text;
        this.$nextTick(() => (this.columnName = null));
        this.attributePlaceholder = this.$t(
          'createCertModal.backSide.placeholder[2]'
        );
        console.log('attribute match', this.columnName);
      } else if (typeof v === 'object') {
        this.input = this.inputList[this.$i18n.locale].find(
          (e) => v.type == e.value
        );
        console.log('Input match', this.input);
        // newValue.text = v;
        // this.attributeList.push(newValue);
      }
      if (!v) {
        this.attributePlaceholder = this.$t(
          'createCertModal.backSide.placeholder[1]'
        );
      }
    },
    updateInitialFields(e) {
      this.techList = e.items.map((i) => {
        return i.item;
      });
      this.$emit('update', this.techList);
    },
    removeField(e) {
      this.techList.splice(e.index, 1);
      this.$emit('update', this.techList);
    },
  },
};
</script>

<style></style>
