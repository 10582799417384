<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal-wallid"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header">
            {{ $t('createCertModal.title') }}
            <v-spacer />
            <button
              v-if="isMaster"
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
              <IconClose />
            </button>
          </slot>
        </header>

        <div class="modal-body">
          <slot name="body">
            <v-stepper class="stepper-signup" v-model="step">
              <v-stepper-items>
                <v-stepper-content step="0" class="pa-0">
                  <v-container class="">
                    <v-row>
                      <v-col cols="12" class="pt-1">
                        <p class="subtitle_text mb-1">
                          {{ $t('createCertModal.card') }}
                        </p>
                      </v-col>
                      <v-col cols="12" md="12" class="pb-1">
                        <CustomCard
                          v-if="!loadingTemplate"
                          :large="true"
                          :frontTemplate="frontTemplate"
                          :backTemplate="backTemplateHeaders"
                          :sigs="signatures"
                          :logos="imagesArray"
                          :caName="caName"
                          :credentialName="credentialName"
                          :urlPhoto="credentialLogo"
                          :frontend_props="frontend_props"
                          :width="width"
                          :height="width / A4ratio"
                        />
                      </v-col>
                      <v-col cols="12" md="12" class="pb-2 pt-1">
                        <p class="light_text">
                          {{ $t('createCertModal.hintCard') }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <v-row class="justify-end" no-gutters>
              <v-btn class="back mr-4" v-if="step == 1" @click="back()">{{
                $t('button.back')
              }}</v-btn>

              <!-- <v-btn
                v-show="step == 0"
                class="next"
                :loading="isDisabled"
                :disabled="isDisabled"
                @click="next()"
                >{{ advanceButtonText }}</v-btn
              > -->
              <v-btn
                class="next"
                :loading="isDisabled"
                :class="confirmed ? 'confirm' : ''"
                :disabled="isDisabled"
                @click="createTemplateButton()"
                >{{ advanceButtonText }}</v-btn
              >
            </v-row>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconClose from '../assets/icon-close.vue';

import CustomCard from '../components/CustomCard';

import {
  CREATE_TEMPLATE,
  DASHBOARD,
  TEMPLATE_ITEM,
  TEMPLATE_SYNC,
  UPDATE_CA,
  UPLOAD_FILE,
} from '../store/actions';

import { mapGetters } from 'vuex';

const API_URL_IMAGES = process.env.VUE_APP_API_URL_IMAGES;

export default {
  name: 'NewTemplateKeepCodingV1',
  components: {
    IconClose,
    CustomCard,
  },
  data() {
    return {
      step: 0, //0
      side: -1,
      currentColor: '#fb963c',
      hasBack: false,
      confirm: false,
      sending: false,
      confirmed: false,
      urlPhoto: null,
      logos: [],
      frontend_props: {},
      signatures: [],
      publicAttribute: '',
      advanceButtonText: this.$t('button.create'),
      hasErrors: false,
      errorsMsg: {},

      width: 450,
      A4ratio: 1.4142,

      imagesArray: [
        { url: require('../assets/templates/keepCoding-logo.png') },
      ],
      background_url: null,
      credentialLogo: null,
      credentialName: null,

      loadingTemplate: true,
      frontTemplate: [],
      backTemplate: [
        { text: 'Tecnología', value: 'tecnologia', input: 'text' },
        { text: 'Notas', value: 'grade', input: 'text' },
        { text: 'Horas', value: 'text', input: 'number' },
      ],
      backTemplateHeaders: {},

      templateAttributes: ['nombre', 'status', 'fecha inicio', 'fecha fin'],
    };
  },
  created() {
    this.debug(`*** ${this.$options.name} ***`);
  },
  async mounted() {
    //Create Custom Card
    this.credentialLogo = `${API_URL_IMAGES}/logos/keepCoding-logo.png`;
    this.frontend_props.background_url = `${API_URL_IMAGES}/backgrounds/keepCoding-bg-1.png`;

    this.frontend_props.customTemplateName = 'keepCodingV1';

    this.frontend_props.font = 'Roboto';

    this.credentialName = 'Full Stack Mobile Bootcamp 10ª Edición';

    this.frontTemplate = this.templateAttributes.map((a) => {
      return {
        attr: a,
        type: 'text',
        isPublic: true, //this.publicAttribute,
        isMandatory: true,
      };
    });

    this.createTableHeaders();

    this.loadingTemplate = false;
  },
  computed: {
    ...mapGetters(['isMaster', 'caName', 'photoURL', 'cid', 'photoHandle']),

    isDisabled() {
      return this.hasErrors || this.sending;
      // return false;
    },
  },
  watch: {
    side() {
      // let sides = ["", "back"];

      //Scroll to @sides on change
      // let side = sides[id];
      // if (side) this.scrollIntoID(side, 500);

      //Clear all error on template side change
      this.backTemplate = [];
      this.backTemplateHeaders = {};
      Object.keys(this.errorsMsg).forEach((error) => this.clearError(error));
    },
  },
  methods: {
    /**
     *
     * Delete @error from errors list
     */
    clearError(error) {
      this.$delete(this.errorsMsg, error);
      this.hasErrors = Object.keys(this.errorsMsg).length != 0;
    },

    checkForm() {
      this.errorsMsg = {};
      this.hasErrors = false;

      if (!this.credentialName) {
        this.errorsMsg['errorCertName'] = this.$t(
          'createCertModal.errorCertName'
        );
      }
      this.debug('imagesArray', this.imagesArray);
      if (this.imagesArray.length < 1) {
        this.errorsMsg['errorUrlPhoto'] = this.$t(
          'createCertModal.errorUrlPhoto'
        );
      }

      this.debug('signatures', this.signatures);
      if (this.signatures.length < 1) {
        this.errorsMsg['errorSignatures'] = this.$t(
          'createCertModal.signatures.error'
        );
      }

      if (this.frontTemplate.length < 1) {
        this.errorsMsg['errorAttribute'] = this.$t(
          'createCertModal.errorAttribute'
        );
        this.errorsMsg['errorInputFront'] = this.$t(
          'createCertModal.errorInput'
        );
      }
      this.debug('this.backtemplate', this.backTemplate);
      if (this.side == 1 && this.backTemplate.length < 1) {
        this.errorsMsg['errorModule'] = this.$t('createCertModal.errorModule');
        this.errorsMsg['errorType'] = this.$t('createCertModal.errorType');
        this.errorsMsg['errorInputBack'] = this.$t(
          'createCertModal.errorInput'
        );
      }

      this.hasErrors =
        Object.keys(this.errorsMsg).length > 0 &&
        this.errorsMsg.constructor === Object;

      if (this.hasErrors) {
        this.scrollIntoError(Object.entries(this.errorsMsg)[0][0], -200);
      }

      this.debug('hasErrors ', this.hasErrors);
      this.debug('hasErrors ', this.errorsMsg);

      return this.hasErrors;
    },

    /**
     * On close reloads dashboard before closing this modal
     */
    close(e) {
      this.$store.dispatch(DASHBOARD).then(() => {
        this.$emit('close', e);
      });
    },
    back() {
      this.confirmed = false;
      this.sending = false;
      switch (this.step) {
        case 1:
          this.step -= 1;
          this.advanceButtonText = this.$t('button.next');

          break;

        default:
          this.$emit('back');

          break;
      }
    },

    async createTemplateButton() {
      this.sending = true;
      // this.advanceButtonText = this.$t("button.confirm");
      this.debug('Call createTemplate ');
      // this.frontend_props.background_url = await this.uploadPhoto(
      //   this.background_url,
      //   "background"
      // ); //await this.generatePNGfromSVG();

      // if (this.imagesArray.length > 0) {
      //   this.uploadPhotos(this.imagesArray)
      //     .then(() => {
      this.debug('logos for upload: ', this.credentialLogo);
      this.updateCA(this.credentialLogo);
      //     })
      //     .catch((err) => {
      //       this.logError(err);
      //     });
      // }
    },
    next() {
      if (!this.checkForm()) {
        this.debug('Advance step ');
        this.step += 1;
        this.advanceButtonText = this.$t('button.create');

        this.frontend_props.color = this.currentColor;

        let sigs = [];
        if (this.signatures && this.signatures.length > 0) {
          sigs = this.signatures;
        }

        this.frontend_props.sigs = sigs;
        this.frontend_props.logos = this.imagesArray;
        this.debug(this.frontend_props);
      }
    },

    createTableHeaders() {
      if (this.backTemplate && this.backTemplate.length > 0) {
        this.backTemplateHeaders = this.createTable(this.backTemplate);
      }
    },
    createTable(template) {
      this.debug(template);
      let attrs = [];

      let headersTable = template.map((e) => {
        this.debug(e);
        attrs.push({ attr: e.text.toLowerCase(), type: e.input });

        return {
          text: e.text,
          align: 'start',
          value: e.text.toLowerCase(),
          type: e.input,
        };
      });

      // attrs.unshift({ attr: template[0].module, type: "text" });

      // headersTable.unshift({
      //   text: template[0].module,
      //   align: "start",
      //   value: template[0].module.toLowerCase(),
      //   type: "text",
      // });
      this.debug(headersTable);

      return { headers: headersTable, attrs: attrs };
    },
    addTemplateAttr(data) {
      let attrs = this.frontTemplate.map((item, index) => {
        this.debug(item, index);
        var newItem = {
          attr: item.attr,
          type: item.type,
          // value: item.value,
          isPublic:
            item.attr.toLowerCase() == 'nome' ||
            item.attr.toLowerCase() == 'name' ||
            item.attr.toLowerCase() == 'nombre', //this.publicAttribute,
          isMandatory: item.isMandatory, // adicionar var para marcar se é obrigatorio
          order: index,
        };
        return newItem;
      });
      let tables = [];
      //TO DO: possibilidade de adicionar mais tabelas
      this.debug('backTemplate', this.backTemplate);

      if (this.backTemplate && this.backTemplate.length > 0) {
        tables.push(this.createTable(this.backTemplate));
      }

      this.debug('tables', tables);

      this.debug('data', data);
      this.debug('TEMPLATE_ITEM', TEMPLATE_ITEM);
      this.debug('TEMPLATE_SYNC', TEMPLATE_SYNC);
      this.debug('attrs', attrs);

      this.$store
        .dispatch(TEMPLATE_ITEM, {
          tid: data.tid,
          attrs,
          tables,
        })
        .then((res) => {
          this.debug(res);
          let tid = res.data.data.template.tid;
          this.$store
            .dispatch(TEMPLATE_SYNC, res.data.data)
            .then(() => {
              this.close({ tid, name: '' });
            })
            .catch((err) => {
              console.error(err);
              this.$store.commit('errorMsg', err.message || err);
              this.sending = false;
            });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('errorMsg', err.message || err);
        });
    },
    uploadPhoto(file, folder) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(UPLOAD_FILE, { file, folder })
          .then((url) => {
            resolve(url);
          })
          .catch((err) => {
            this.logError('uploadPhotos', err);
            reject(err);
          });
      });
    },
    uploadPhotos() {
      let promises = [];
      for (let i = 0; i < this.imagesArray.length; i++) {
        promises.push(
          new Promise((resolve, reject) => {
            this.$store
              .dispatch(UPLOAD_FILE, { file: this.imagesArray[i].file })
              .then((url) => {
                this.logos.push({
                  url: url,
                });
                resolve();
              })
              .catch((err) => {
                this.logError('uploadPhotos', err);
                reject(err);
              });
          })
        );
      }
      return Promise.all(promises);
    },

    updateCA(photoURL) {
      this.$store
        .dispatch(UPDATE_CA, { name: this.caName, img_url: photoURL })
        .then((cid) => {
          if (cid) {
            this.createTemplate();
          }
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('errorMsg', err.message || err);
          this.sending = false;
        });
    },
    createTemplate() {
      this.frontend_props.logos = this.logos;

      this.$store
        .dispatch(CREATE_TEMPLATE, {
          name: this.credentialName,
          frontend_props: this.frontend_props,
        })
        .then((data) => {
          this.debug(data);
          this.addTemplateAttr(data);
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('errorMsg', err.message || err);
          this.sending = false;
        });
    },
  },
};
</script>

<style scoped>
.modal-body {
  padding: 0;
  padding-left: 16px;
}
</style>

<style lang="scss">
.modal-wallid {
  width: 830px;
  .confirm.v-btn {
    background-color: #00808e !important ;
  }
  .container.scroll {
    max-height: 420px;
    overflow: auto;
    padding-right: 18px;
  }
  // .static_image {
  //   .v-image {
  //     position: absolute;
  //   }
  // }
  .v-btn.add_more {
    border-radius: 4px;
    border: solid 1px var(--teal-blue);
    min-width: unset;
    padding: 0 16px;
    height: 44px;
    color: var(--teal-blue);
    max-width: 102px;
    .v-btn__content {
      text-transform: none;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--teal-blue);
      svg {
        margin-right: 8px;
      }
    }
    &.v-btn--disabled {
      color: #d8d8d8 !important;
      border-color: #d8d8d8 !important;
      .v-btn__content {
        color: #e5e5e5;

        svg g {
          fill: #e5e5e5;
        }
      }
    }
  }
  .input-field {
    .v-input--radio-group {
      .v-input__slot {
        border: none !important;
      }
    }
    .v-input__control {
      min-height: unset;
      .v-input__slot {
        margin-bottom: 4px;
        height: 44px;
        border-radius: 3px;
      }
      .v-text-field__details {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
