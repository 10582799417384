<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols="12">
        <p class="mb-0 strong_text">
          {{ $t('createCertModal.front') }}
        </p>
      </v-col>
      <v-col cols="12">
        <p class="mb-0 small_text">
          {{ $t('createCertModal.frontSide.add[0]') }}
        </p>
      </v-col>

      <v-col id="errorAttribute" class="pr-2 py-2 input-field">
        <label class="">{{
          $t('createCertModal.frontSide.dropdown[0]')
        }}</label>

        <v-combobox
          class="field_changer"
          disable-lookup
          v-model="attribute"
          :items="attributeList[$i18n.locale]"
          :placeholder="attributePlaceholder"
          single-line
          attach
          :ripple="false"
          :error-messages="errorsMsg['errorAttribute']"
          :menu-props="{ contentClass: 'field_changer select' }"
          @input="addToList"
        >
          <template v-slot:append> <IconDropDown /> </template>
          <template v-slot:item="{ item }">
            <IconAdd class="mr-1 mt-0" v-if="item.type === 'custom'" />
            <p
              class="v-list-item__title py-2 mb-0"
              :style="item.type === 'custom' ? 'color: var(--teal-blue);' : ''"
              hide-details
              solo
            >
              {{ item.text }}
            </p>
          </template>
        </v-combobox>
      </v-col>
      <v-col class="pr-2 py-2 input-field">
        <label class="">{{
          $t('createCertModal.frontSide.dropdown[1]')
        }}</label>
        <v-combobox
          class="field_changer"
          disable-lookup
          v-model="input"
          :items="inputList[$i18n.locale]"
          :placeholder="$t('createCertModal.frontSide.placeholder[1]')"
          single-line
          attach
          :ripple="false"
          :error-messages="errorsMsg['errorInputFront']"
          :menu-props="{ contentClass: 'field_changer select' }"
        >
          <template v-slot:append> <IconDropDown /> </template>
        </v-combobox>
      </v-col>
      <v-col class="pt-11 pb-2 px-1" style="max-width: 104px">
        <v-btn class="add_more" :disabled="isAddDisabled" text @click="add()">
          <!-- <IconAdd /> -->
          {{ $t('createCertModal.frontSide.add[1]') }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="sortedFieldsList.length > 0" cols="12" md="12" class="py-0">
        <grid
          :draggable="true"
          :sortable="true"
          :items="sortedFieldsList"
          :height="100"
          :gridWidth="750"
          :hasMandatory="true"
          @dragend="updateInitialFields"
          @remove="removeField"
          @updateMandatory="updateMandatory"
        >
        </grid>
      </v-col> </v-row
  ></v-container>
</template>

<script>
import IconDropDown from '../../assets/icon-dropdown.vue';
import IconAdd from '../../assets/icon-add.vue';
import Grid from '../Grid';

export default {
  name: 'FrontSideTemplate',
  components: {
    IconAdd,
    IconDropDown,
    Grid,
  },
  props: {
    errorsMsg: {
      type: Object,
      required: true,
    },
  },
  watch: {
    errorsMsg: {
      deep: true,
      handler(value) {
        console.log('array updated', value);
      },
    },
    sortedFieldsList(value) {
      if (value.length > 0) {
        this.$emit('clearError', 'errorAttribute');
        this.$emit('clearError', 'errorInputFront');
      }
    },
  },
  computed: {
    isAddDisabled() {
      return !this.input || !this.attribute;
    },
  },
  data() {
    return {
      sortedFieldsList: [],
      input: null,
      attributePlaceholder: this.$t('createCertModal.frontSide.placeholder[0]'),
      inputList: {
        pt: [
          { text: 'texto', value: 'text' },
          { text: 'dd--mm-aaaa', value: 'date' },
          { text: 'número', value: 'number' },
        ],
        en: [
          { text: 'text', value: 'text' },
          { text: 'dd--mm-yyyy', value: 'date' },
          { text: 'number', value: 'number' },
        ],
        es: [
          { text: 'texto', value: 'text' },
          { text: 'dd--mm-yyyy', value: 'date' },
          { text: 'numero', value: 'number' },
        ],
        //
      },
      attribute: null,
      attributeList: {
        en: [
          { text: 'Name', value: 'name', type: 'text' },
          { text: 'Team Name', type: 'text' },
          { text: 'Class', type: 'text' },
          { text: 'Issue Date', type: 'date' },
          { text: 'Expiration Date', type: 'date' },
          { text: 'Duration', type: 'text' },
          { text: 'Specialization', type: 'text' },
          { text: 'Edition', type: 'text' },
          { text: 'Add another', type: 'custom' },
        ],
        pt: [
          { text: 'Nome', value: 'name', type: 'text' },
          { text: 'Nome da equipa', type: 'text' },
          { text: 'Turma', type: 'text' },
          { text: 'Data conclusão', type: 'date' },
          { text: 'Data expiração', type: 'date' },
          { text: 'Duração', type: 'text' },
          { text: 'Especialização ', type: 'text' },
          { text: 'Edição', type: 'text' },
          { text: 'Adicionar Outro', type: 'custom' },
        ],
        es: [
          { text: 'Nombre', value: 'name', type: 'text' },
          { text: 'Fecha Inicio', type: 'date' },
          { text: 'Fecha Fin', type: 'date' },
          { text: 'Status', type: 'text' },
          { text: 'Adicionar Outro', type: 'custom' },
        ],
      },
    };
  },
  methods: {
    updateMandatory(e) {
      this.sortedFieldsList[e.index].isMandatory = e.value;
      this.$emit('update', this.sortedFieldsList);
    },
    add() {
      if (!this.attribute.id) {
        this.sortedFieldsList.push({
          attr: this.attribute.text || this.attribute,
          // value: this.attribute.value || this.attribute.text || this.attribute,
          type: this.input.text,
          input: this.input.value,
          isMandatory: true,
        });
        this.attributePlaceholder = this.$t(
          'createCertModal.frontSide.placeholder[0]'
        );
        this.attribute = null;
        this.input = null;
      }
      this.$emit('update', this.sortedFieldsList);
    },
    addToList(v) {
      console.log('Attribute', v);

      //   var newValue = { text: "", value: this.attributeList.length };
      if (v.type && v.type == 'custom') {
        // newValue.text = v.text;
        this.$nextTick(() => (this.attribute = null));
        this.attributePlaceholder = this.$t(
          'createCertModal.frontSide.placeholder[2]'
        );
        console.log('attribute match', this.attribute);
      } else if (typeof v === 'object') {
        this.input = this.inputList[this.$i18n.locale].find(
          (e) => v.type == e.value
        );
        console.log('Input match', this.input);
        // newValue.text = v;
        // this.attributeList.push(newValue);
      }
      if (!v) {
        this.attributePlaceholder = this.$t(
          'createCertModal.frontSide.placeholder[1]'
        );
      }
    },
    updateInitialFields(e) {
      this.sortedFieldsList = e.items.map((i) => {
        return i.item;
      });
      this.$emit('update', this.sortedFieldsList);
    },
    removeField(e) {
      this.sortedFieldsList.splice(e.index, 1);
      this.$emit('update', this.sortedFieldsList);
    },
  },
};
</script>

<style></style>
