<template>
  <v-container class="draggable_field py-0">
    <v-row class="outline">
      <v-col cols="1" class="px-1 drag" :class="hasMandatory ? '' : 'py-1'">
        <p>{{ index + 1 }}</p>
        <IconReorganize :style="hasMandatory ? '' : 'height:9px'" />
      </v-col>

      <v-col class="field px-2" :class="hasMandatory ? '' : 'py-1'">
        <v-row no-gutters class="">
          <v-col cols="11" class="py-0">
            <p class="mb-1 attribute">
              {{ item.attr }}
              <span v-if="item.isMandatory" style="color: #e95e5e"> * </span>
            </p>
          </v-col>
          <v-col
            v-if="item.attr != 'Credential ID'"
            cols="1"
            @click="deleteAttr()"
            class="py-0 close"
          >
            <IconClose :style="hasMandatory ? '' : 'width:9px'" />
          </v-col>
          <v-col class="py-0 pr-0">
            <p class="mb-0 input">
              {{ item.type }}
            </p>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" class="px-1 py-0">
            <v-checkbox
              v-if="hasMandatory"
              class="mt-0 pt-0 w-checkbox shrink"
              color="#009fb1"
              :ripple="false"
              :hide-details="true"
              :input-value="item.isMandatory"
              @change="updateMandatory"
              :label="$t('createCertModal.frontSide.mandatory')"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IconReorganize from '../../assets/icon-reorganize.vue';
import IconClose from '../../assets/icon-close.vue';

export default {
  props: ['index', 'item', 'remove', 'hasMandatory'],
  components: { IconReorganize, IconClose },
  methods: {
    deleteAttr() {
      this.remove();
    },
    updateMandatory(e) {
      this.$emit('updateMandatory', { index: this.index, value: e });
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.draggable_field {
  .row.outline {
    border-radius: 6px;
    border: solid 1px var(--pale-blue);
    background-color: var(--pale-blue);
    &:hover {
      box-shadow: 0 2px 4px 0 rgba(149, 149, 149, 0.5);

      border-color: var(--teal-blue);
      .close {
        cursor: pointer;
        svg g {
          fill: #373c43;
        }
      }
      .drag {
        svg g {
          fill: #ffffff;
        }
      }
    }
  }
  .close {
    // background-color: var(--pale-blue);
    // border-top-right-radius: 6px;
    // border-bottom-right-radius: 6px;
    svg {
      margin-left: -4px;
      g {
        fill: var(--pale-blue);
      }
    }
  }
  .drag {
    background-color: #42a4b1;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    svg g {
      fill: #42a4b1;
    }
    p {
      margin: 0;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--white);
    }
  }
  .field {
    background-color: var(--pale-blue);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .attribute {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
  }
  .input {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--light-grey-blue);
    width: max-content;
  }
}
</style>
